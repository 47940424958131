import { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";

export default function usePersistQueryParam<State, Actions>(
  id: string,
  reducerFn: React.Reducer<State, Actions>,
  initialState: State,
  stateToQueryParamConverter: (state: State) => string,
  initializer?: (initialState: State) => State,
): [State, React.Dispatch<Actions>] {
  const navigate = useNavigate();
  const init = initializer ?? ((initialState) => initialState);
  const [state, setState] = useReducer(reducerFn, initialState, init);
  useEffect(() => {
    const filterQueryArguments = stateToQueryParamConverter(state);
    navigate(
      {
        pathname: window.location.pathname,
        search: filterQueryArguments,
      },
      { replace: true },
    );
  }, [id, state]);

  return [state, setState];
}
